import prismicColors from "@/lib/prismicio/prismic-colors.json";
import { useTextConversionMethod } from "@/lib/prismicio/useTextConversion";
import { cn, useFacetExcessive } from "@jog/react-components";
import { colorLanguageConverter } from "@jog/shared";
import { useState } from "react";
import { BOX_CLASSNAME } from "../FormField/const";
const Input = ({ checked, onChange, ...props }) => {
    const [_checked, setChecked] = useState(checked);
    return (<input type="checkbox" checked={_checked} onChange={(e) => {
            setChecked((v) => !v);
            onChange === null || onChange === void 0 ? void 0 : onChange(e);
        }} {...props}/>);
};
export const FacetSearch = () => {
    const { facet, searchable, searchValue, setSearchValue, attributes } = useFacetExcessive();
    if (!searchable)
        return <></>;
    return (<>
            <div className={cn(BOX_CLASSNAME, "mb-2 w-full bg-white")}>
                <input className="w-full focus:outline-0" type="text" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={facet.facetsettings.searchplaceholder}/>
            </div>
            {attributes.length === 0 && (<div className="py-1 text-sm text-gray-main">{facet.facetsettings.searchnoresultstext}</div>)}
        </>);
};
export const CheckboxFacet = ({ pending, onChange }) => {
    const textConvert = useTextConversionMethod();
    const { display, Showmore, facet, attributes } = useFacetExcessive();
    if (!facet)
        return;
    return (<>
            <FacetSearch />
            <ul>
                {attributes.map((attribute, index) => (<li key={attribute.url} className={cn("py-1", display(index))}>
                        <label className="flex items-center">
                            <Input type="checkbox" disabled={pending} value={attribute.title} checked={attribute.isselected} onChange={() => attribute.url && (onChange === null || onChange === void 0 ? void 0 : onChange(attribute.url))}/>
                            {facet.facetsettings.title === "Kleur" && (<div className="mr-1.25 inline-block h-4.5 w-4.5 cursor-pointer border border-gray-silver" style={{ backgroundColor: colorLanguageConverter(attribute.title, prismicColors) }}/>)}
                            <span>{textConvert(attribute.title)}</span>
                            <span className="inline-block pl-1.25 text-sm text-gray-main">
                                ({attribute.nrofresults})
                            </span>
                        </label>
                    </li>))}
            </ul>
            <Showmore className="mt-1.25 py-1 text-base lg:hover:text-red-mona"/>
        </>);
};
